import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Grid } from "@mui/material";

import { changeUser } from "api/features/User/userApi";

import TextFieldInput from "components/molecules/Inputs/TextFieldInput";
import OutlinedSelectField from "components/molecules/OutlinedSelectField";
import { pick } from "lodash";
import logger from "loggers";
import { Profile } from "models";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ChangeFormWrapper } from "../components";

import { ChangeProfileFormProps, profileValidationSchema } from "../constants";
import { IOption } from "../models";

interface Props {
	profile: Profile;
	countryOptions: IOption[];
	languageOptions: IOption[];
	isMobile: boolean;
	step?: number;
	changeStep: Dispatch<SetStateAction<number | undefined>>;
	updateProfile: Dispatch<SetStateAction<Profile | undefined>>;
}

const ChangeProfileForm: FC<Props> = ({
	profile,
	countryOptions,
	languageOptions,
	step,
	changeStep,
	isMobile,
	updateProfile
}) => {
	const { t } = useTranslation();
	const [apiError, setApiError] = useState<string | null>(null);

	const isEditable = useMemo(() => !!profile.digital_id, [profile]);

	const {
		control,
		handleSubmit,
		reset,
		formState: { isValid, isDirty, isSubmitting }
	} = useForm<ChangeProfileFormProps>({
		mode: "onChange",
		resolver: yupResolver(profileValidationSchema(t)),
		defaultValues: {
			...pick(profile, ["title", "firstName", "lastName", "email", "login", "countryCode", "preferredLanguage"])
		}
	});

	const onSubmit = async data => {
		try {
			/* eslint-disable no-console */
			console.log(data);
			/* eslint-enable no-console */
			const user = await changeUser(data).catch(e => logger.error(e));
			reset(user);
			updateProfile({ ...user });
			if (step === 2) {
				changeStep(1);
			}
		} catch (err) {
			const e = err as { response: { message: string } };
			setApiError(e.response.message);
		}
	};

	return (
		<ChangeFormWrapper
			step={step}
			title={t("account.form.title")}
			onSubmit={handleSubmit(onSubmit)}
			apiError={apiError}
			isMobile={isMobile}
			isDirty={isDirty && isEditable}
			isValid={isValid}
			isSubmitting={isSubmitting}
			submitButtonTitle={t("account.buttons.save")}
			changeStep={changeStep}
		>
			<Grid item xs={12} md={12}>
				<Grid item xs={12} md={6}>
					<OutlinedSelectField
						disabled={!isEditable}
						label={t("account.form.fields.title.label")}
						type="select"
						placeholder={t("account.form.fields.title.placeholder")}
						name="title"
						control={control}
						defaultValue=""
						options={[
							{ value: "Dr.", label: t("dropdowns.title.dr") },
							{ value: "Mr.", label: t("dropdowns.title.mr") },
							{ value: "Mrs.", label: t("dropdowns.title.mrs") }
						]}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} md={6}>
				<Controller
					name="firstName"
					control={control}
					defaultValue=""
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextFieldInput
							inputProps={{
								maxLength: 100
							}}
							disabled={!isEditable}
							placeholder={t("account.form.fields.firstName.placeholder")}
							variant="outlined"
							value={value}
							onChange={e => {
								const v = e.target.value?.replace(/^\s*/gm, "");
								onChange(v);
							}}
							label={t("account.form.fields.firstName.label")}
							error={!!error}
							helperText={error ? error.message : null}
							type="text"
						/>
					)}
				/>
			</Grid>
			<Grid item md={6} xs={12}>
				<Controller
					name="lastName"
					control={control}
					defaultValue=""
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextFieldInput
							inputProps={{
								maxLength: 100
							}}
							disabled={!isEditable}
							placeholder={t("account.form.fields.lastName.placeholder")}
							variant="outlined"
							value={value}
							onChange={e => {
								const v = e.target.value?.replace(/^\s*/gm, "");
								onChange(v);
							}}
							label={t("account.form.fields.lastName.label")}
							error={!!error}
							helperText={error ? error.message : null}
							type="text"
						/>
					)}
				/>
			</Grid>
			<Grid item md={6} xs={12}>
				<Controller
					name="email"
					control={control}
					defaultValue=""
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<TextFieldInput
							inputProps={{
								maxLength: 100
							}}
							disabled={!isEditable}
							placeholder={t("account.form.fields.email.placeholder")}
							variant="outlined"
							value={value}
							onChange={e => {
								const v = e.target.value?.replace(/^\s*/gm, "");
								onChange(v);
							}}
							label={t("account.form.fields.email.label")}
							error={!!error}
							helperText={error ? error.message : null}
							type="email"
						/>
					)}
				/>
			</Grid>
			<Grid item md={6} xs={12}>
				<OutlinedSelectField
					required
					disabled={!isEditable}
					label={t("account.form.fields.countryCode.label")}
					type="select"
					placeholder={t("account.form.fields.countryCode.placeholder")}
					name="countryCode"
					control={control}
					defaultValue=""
					options={countryOptions}
				/>
			</Grid>
			<Grid item xs={12}>
				<OutlinedSelectField
					disabled={!isEditable}
					label={t("account.form.fields.preferredLanguage.label")}
					type="select"
					required
					placeholder={t("account.form.fields.preferredLanguage.placeholder")}
					name="preferredLanguage"
					control={control}
					defaultValue=""
					options={languageOptions}
				/>
			</Grid>
		</ChangeFormWrapper>
	);
};

export default ChangeProfileForm;
